<script lang="ts">
	import { is_feature_enabled } from '$lib/features_utils';
	import { show_subscribe_popup } from '$lib/stores/subscribe_popup_store';
    import { Drawer, CloseButton } from 'flowbite-svelte';
    import { sineIn } from 'svelte/easing';
	import Button from '../Button.svelte';
	import SubscriptionFeaturesTable from './SubscriptionFeaturesTable.svelte';
  
    $: hidden = $show_subscribe_popup === false;
    let transitionParamsBottom = {
      y: 320,
      duration: 200,
      easing: sineIn
    };

    function close() {
        show_subscribe_popup.set(false);
    }

    async function subscribe() {
        console.log("Subscribe!");
    }

  </script>
    
  <Drawer placement="bottom" 
          width="w-full" 
          transitionType="fly" 
          transitionParams={transitionParamsBottom} 
          activateClickOutside={false}
          bind:hidden={hidden}
          divClass="overflow-y-auto z-[999] p-4 bg-backdrop-dark rounded-t-3xl text-primary-foreground-enabled max-h-[100vh]">

    <div class="flex items-center">
      <!-- <h5 id="drawer-label" class="inline-flex items-center mb-4 text-base font-semibold ">
        Subscription Plans
      </h5> -->
      <CloseButton on:click={close} />
    </div>

    <div class="pb-12">
        <h5 class="text-lg font-semibold">
            Compare Plans
        </h5>
        <p class="pt-2 text-xs text-gray-400 dark:text-gray-500">
            Subscribe to enjoy full membership benefits.
        </p>        
        <div class="flex flex-col place-items-center mt-8 mx-4">
            <Button on_click={subscribe} class_override="w-full flex place-content-center">
                Subscribe
            </Button>
            <div class="text-[0.65rem] font-extralight mt-2">
                Cancel any time.
            </div>
        </div>
    </div>
    <div class="relative overflow-y-auto h-[55dvh]">

        <SubscriptionFeaturesTable />

    </div>

</Drawer>