<script lang="ts">
	import { activity_sync_store } from "$lib/stores/activity_sync_store";
    import { notification_store } from "$lib/stores/notification_store";
	import { service_worker_installation } from "$lib/stores/service_worker_installation_store";
	import { user_session } from "$lib/stores/user_session_store";
	import { onMount } from "svelte";
       
    let user_session_store = user_session();
    let {user_settings} = $user_session_store;
    let user_logged_in = user_settings?.enabled ?? false;
    // let refreshing = false;

    const MAX_RETRY_DURATION_IN_MILLIS = 1000 * 60 * 5; // 5 minutes
    const RETRY_SLEEP_IN_MILLIS = 1000 * 5; // 5 seconds
    /*
     * Function starts a timer that checks for the activity_sync_store.active flag
     * to be false in order to know when to reload the page. This timer will 
     * repeatedly check the flag every 5 seconds up until 5 minutes have passed.
     */
    function schedule_reload() {

        if ($activity_sync_store.active === false) {

            console.log("SERVICE WORKER - Reloading page now...");
            window.location.reload();

        } else {

            let count = 0;
            let interval_id = setInterval(() => {

                console.log("SERVICE WORKER - Reload requested, checking if possible.");
                if ($activity_sync_store.active === false) {

                    clearInterval(interval_id);
                    console.log("SERVICE WORKER - Reloading page...");
                    window.location.reload();
                
                } else {

                    count += 1;
                    if (count >= Math.round(MAX_RETRY_DURATION_IN_MILLIS / RETRY_SLEEP_IN_MILLIS)) {

                        console.log("SERVICE WORKER - Reload failed after 5 minutes.");
                        clearInterval(interval_id);
                    }

                    console.log(`SERVICE WORKER - Checking again for reload opportunity in ${RETRY_SLEEP_IN_MILLIS / 1000} seconds.`);
                }

            }, RETRY_SLEEP_IN_MILLIS);
        }
    }

    onMount(() => {

        if (user_logged_in && 'serviceWorker' in navigator) {

            navigator.serviceWorker.register('/service-worker.js', { type: 'module', scope: '/' }).then(registration => {

                registration.addEventListener('updatefound', () => {
                    
                    console.log("SERVICE WORKER - UPDATE FOUND.");
                    $service_worker_installation = 'update_found';

                    const newWorker = registration.installing;
                    if (newWorker) {
                        
                        newWorker.addEventListener('statechange', () => {
                            
                            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {

                                $service_worker_installation = 'installed';
                                notification_store.notify_upgrade_available(() => {

                                    newWorker.postMessage({action: 'skipWaiting'});

                                    console.log("SERVICE WORKER - Schedule page reloading!");
                                    schedule_reload();
                                });
                            }
                        });
                    }
                });

                navigator.serviceWorker.addEventListener('controllerchange', (event: any) => {
                                    
                    $service_worker_installation = 'controller_change';
                    console.log("SERVICE WORKER - Controller change detected...");
                });
            });
        }
    });

    // afterNavigate(() => {

    //     console.log("SERVICE WORKER - Checking for updates...", $service_worker_installation, $activity_sync_store.active);
    //     if ($service_worker_installation === 'controller_change' && $activity_sync_store.active !== true) {

    //         if (!refreshing) {

    //             console.log("SERVICE WORKER CONTROLLER CHANGE ---- Reloading page...");
    //             refreshing = true;
    //             window.location.reload();
    //         }
    //     }
    // });

</script>
  