<script lang="ts">
  	import { createEventDispatcher } from "svelte";
    import { page } from '$app/stores';

    const dispatch = createEventDispatcher();
    function handleClick(event:any) {

        dispatch('menu-item-click');
    }

    export let icon_svg_path: string;
    export let url: string;
    export let label: string;
    export let mobile: boolean;
    export let full_page_reload = false;

    let url_pathname = new URL(url, $page.url).pathname;
    $: selected = $page.url.pathname === url_pathname;

</script>

{#if mobile}

  <a href="{url}"
     data-sveltekit-reload={full_page_reload} 
     on:click={handleClick} class:bg-slate-700={selected} 
     class="group flex items-center rounded-md p-2 text-base font-medium text-slate-300 hover:bg-gray-50 hover:text-gray-900">

      <!-- Heroicon name: outline/X -->
      <svg class="ml-10 mr-4 h-6 w-6 text-slate-300 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="{icon_svg_path}" />
      </svg>
      {label}
  </a>

{:else}

  <a href="{url}" on:click={handleClick} class:bg-slate-700={selected} class="flex items-center rounded-lg p-4 text-indigo-200 hover:bg-indigo-700">

      <!-- Heroicon name: outline/X -->
      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="{icon_svg_path}" />
      </svg>
      <span class="sr-only">{label}</span>
  </a>

{/if}


