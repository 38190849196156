<script lang="ts">
	import { page } from "$app/stores";
	import { generate_view_entity_url } from "$lib/client/entity_utils";
	import type { AlertDTO } from "$lib/server/entities/alert/AlertEntity";

    export let item: AlertDTO;

    let entity_type_label: string;
    if (item.discussion) {
        entity_type_label = "discussion";
    } else if (item.track) {
        entity_type_label = "open water swim";
    } else if (item.pool) {
        entity_type_label = "pool swim";
    } else if (item.comment) {
        entity_type_label = "comment";
    } else {
        entity_type_label = "shared content";
    }

    let entity;
    if (item.discussion) {
        entity = item.discussion;
    } else if (item.track) {
        entity = item.track;
    } else if (item.pool) {
        entity = item.pool;
    } else if (item.comment) {
        entity = item.comment;
    } else {
        throw new Error("Unable to render alert: " + item.uuid);
    }

    let entity_url = (generate_view_entity_url(entity, $page.url.toString())).url.toString();

</script>

<a href="{entity_url}" class="flex-1 text-xs leading-6">
    <div class="mb-1.5 text-gray-400"> 
        <span class="font-semibold text-primary-text-diminished">
            {item.created_by_user_account.name}
        </span>
        reacted to your 
        {entity_type_label}.
    </div>
</a>
