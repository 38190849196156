<script lang="ts">
    import { page } from '$app/stores';

    export let popup_menu: boolean;
    export let url: string;
    export let text: string;

    $: selected = $page.url.pathname.endsWith(url);

</script>

{#if popup_menu}
    <a href="{url}" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50">{text}</a>
{:else}
    <a href="{url}" class="{selected ? 'bg-slate-700 rounded-lg' : ''} p-2 m-2 text-base font-medium text-white hover:text-gray-300">{text}</a>
{/if}