import type { BreadcrumbType, Category, LogLevel } from "$lib/observability/LoggerTypes";
import {withScope, type Breadcrumb, captureException, captureMessage, flush } from "@sentry/browser";

/**
 * Error logger adapter function for client code.
 */
export async function log_error(log_level: LogLevel, 
    category: Category,
    payload: {type: 'error', error?: Error} | {type: 'message', message?: string}, 
    app_version: string,
    fingerprint?: string[], 
    context?: any,
    breadcrumbs?: BreadcrumbType[]) {

    withScope(function (scope) {

        if (breadcrumbs) {

            for (let breadcrumb of breadcrumbs) {

                let bc: Breadcrumb = {
                    category,
                    type: 'default',
                    message: breadcrumb.message,
                    data: breadcrumb.data,
                    level: breadcrumb.level,
                    timestamp: breadcrumb.timestamp.getTime()
                };
                scope.addBreadcrumb(bc);
            }
        }

        scope.setLevel(log_level);

        if (fingerprint) {
            scope.setFingerprint(fingerprint);
        }

        let extras = {app_version};
        if (context) {
            extras = {...extras, ...context};
        }
        scope.setExtras(extras);

        let result: string;
        if (payload.type === 'error') {
            result = captureException(payload.error);
        } else {
            result = captureMessage(payload.message!);
        }
    });

    await flush(1000); // delivery timeout in ms
}