<script lang="ts">
	import type { AlertDTO } from "$lib/server/entities/alert/AlertEntity";

    export let item: AlertDTO;

</script>

<a href="/home/{item.user_account.uuid}/friends" class="flex-1 text-xs leading-6">
    <div class="mb-1.5 text-gray-400"> 
        <span class="font-semibold text-primary-text-diminished">
            {item.created_by_user_account.name}
        </span>
        is now following you. 
    </div>
</a>
