<script lang="ts">
    export let url: string;
    export let text: string;
    export let svg_path_data: string | undefined = undefined;
</script>

<a href="{url}" target="_blank" class="text-gray-400 hover:text-white">
    <span class="sr-only">{text}</span>
    <svg class="h-4 w-4 sm:h-6 sm:w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
      <slot>
        <path fill-rule="evenodd" d="{svg_path_data}" clip-rule="evenodd" />
      </slot>
    </svg>
</a>
