<script lang="ts">
    import "../app.css";
    import { page, navigating } from '$app/stores';
	import Header from "$lib/components/layout/header/Header.svelte";
	import Footer from "$lib/components/layout/footer/Footer.svelte";
	import PreloadingIndicator from "$lib/components/PreloadingIndicator.svelte";
	import PwaBootstrap from "$lib/components/PWABootstrap.svelte";
	import NativeBootstrap from "$lib/components/NativeBootstrap.svelte";
	import ToastNotifications from "$lib/components/ToastNotifications.svelte";
	import { onMount } from "svelte";
	import { notification_store } from "$lib/stores/notification_store";
    import { beforeNavigate } from '$app/navigation';
    import type { BeforeNavigate } from '@sveltejs/kit';
	import analytics from "$lib/client/analytics";
	import type { UTMTracking } from "$lib/client/entity_utils";
	import { Capacitor } from "@capacitor/core";
	import AppleHealthKitBootstrap from "$lib/components/apple_health_kit/AppleHealthKitBootstrap.svelte";
	import type { UserSessionSettings } from "../app";
	import { create_user_session_store } from "$lib/stores/user_session_store";
	import SubscriptionPopup from "$lib/components/subscription/SubscriptionPopup.svelte";
	// import { header_menu_enabled } from "$lib/stores/header_menu_enabled_store";
     
    // Populate user_session store for use elsewhere in app
    let user_settings: UserSessionSettings | undefined = $page.data.user_settings;
    let user_account_uuid: string | undefined = $page.data.user_account_uuid;
    create_user_session_store(user_account_uuid, user_settings);

    // $: {
    //     console.log("Page Data -----------> ", $page.data);
    // }
    let is_ios: boolean;

    onMount(() => {

        let url: URL = $page.url;
        if (url && url.searchParams.get("forbidden") === 'true' || url.searchParams.get("error") !== null) {
            notification_store.notify_forbidden();
        }

        // console.log("Recording GA event...");
        // dataLayer.push({'event': 'push_notification'}); // GTM tracking
        // console.log("Finished recording GA event!");

        is_ios = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
    });

    beforeNavigate(async (navigation: BeforeNavigate) => {

        let route_id = navigation.to?.route.id;
        if (route_id === undefined || route_id === null) {
            return;
        }

        // console.log("From: ", JSON.stringify(navigation.from), " - To: ", JSON.stringify(navigation.to), " - Type: ", navigation.type);

        let utm_tracking: UTMTracking | undefined;
        if (navigation.to?.url) {

            let search_parameters = navigation.to.url.searchParams;
            let source = search_parameters.get('utm_source');
            let medium = search_parameters.get('utm_medium');
            let campaign = search_parameters.get('utm_campaign');
            
            if (source || medium || campaign) {
                utm_tracking = 
                    {
                        utm_source: source !== null ? source : undefined, 
                        utm_medium: medium !== null ? medium : undefined, 
                        utm_campaign: campaign !== null ? campaign : undefined
                    };
            }
        }

        await analytics.page_view(route_id, utm_tracking);
    });

</script>

<!-- {#if user_settings?.analytics_enabled ?? false}
    <GoogleTagManager />
{/if} -->
<ToastNotifications />

<!-- <svelte:head>
    {@html webManifest}
</svelte:head> -->

{#if $navigating}
	<PreloadingIndicator />
{/if}
<div class="bg-backdrop-light flex flex-col min-h-screen overflow-visible selection:bg-primary-enabled selection:text-fuchsia-100">

    <Header />
    
    <div class="px-0 flex-1">
        <slot></slot>
    </div>

    <Footer />
</div>

<PwaBootstrap />
<NativeBootstrap />
{#if is_ios}
    <AppleHealthKitBootstrap />
{/if}
<SubscriptionPopup />
